import forcavenda from "assets/imgs/forcavenda.png"
import consultapreco from "assets/imgs/consulta-preco.png"
import integracaoPix from "assets/imgs/integracao-pix.png"
import logo from "assets/imgs/logo.png"
import main from "assets/imgs/main.png"
import bobinas from "assets/imgs/bobinas.png"
import pix from "assets/imgs/pix.png"

import construcao from "assets/imgs/icons/construção.png"
import ferreamentas from "assets/imgs/icons/ferreamentas.png"
import loja from "assets/imgs/icons/loja.png"
import otica from "assets/imgs/icons/otica.png"
import roupas from "assets/imgs/icons/roupas.png"
import supermercado from "assets/imgs/icons/supermercado.png"

import img1 from "assets/imgs/parcerias/01.png"
import img2 from "assets/imgs/parcerias/02.png"
import img3 from "assets/imgs/parcerias/03.png"
import img4 from "assets/imgs/parcerias/04.png"
import img5 from "assets/imgs/parcerias/05.png"
import img6 from "assets/imgs/parcerias/06.png"
import img7 from "assets/imgs/parcerias/07.png"
import img8 from "assets/imgs/parcerias/08.png"
import img9 from "assets/imgs/parcerias/09.png"

export const parcerias = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9
]

export const Icons = {
    construcao,
    ferreamentas,
    loja,
    otica,
    roupas,
    supermercado
}

const Images = {
    forcavenda,
    consultapreco,
    integracaoPix,
    logo,
    main,
    bobinas,
    pix
}

export default Images