import { parcerias } from "utils/imgs"
import Marquee from "react-fast-marquee"
import { FiYoutube } from "react-icons/fi"

const Search = () => {
    return (
        <div className="flex items-center flex-col gap-4 w-full f-c1">
            <h1 className="font-bold  mt-40 mb-20 text-center" style={{ fontFamily: "Sora,Helvetica,sans-serif" }}>
                Tudo que você precisa, <br /> em um só lugar
            </h1>
            <Marquee pauseOnHover className="p-4">
                {parcerias.map((value, index) => (
                    <div key={index} className="h-[220px] w-[280px] mx-3 p-4 rounded-xl">
                        <img src={value}  />
                    </div>
                ))}
            </Marquee>
        </div>
    )
}
export default Search