import {
    FaWhatsapp
} from "react-icons/fa"
import {
    FiChevronUp
} from "react-icons/fi"
const Whatsapp = () => {
    const phoneNumber = 75998670271
    const text = "Olá, gostaria de saber mais sobre o sistema."

    return (
        <a
            href={`https://api.whatsapp.com/send?phone=55${phoneNumber}&text=${text}`}
            target="_blank"
            className="fixed p-2 shadow-lg bottom-10 right-10 z-50 bg-main-50 text-primary-500 font-bold flex flex-row gap-4 items-center cursor-pointer">
            <FaWhatsapp color={"#075e54"} size={25} />
                Whatsapp
            <FiChevronUp size={25} />
        </a>
    )
}
export default Whatsapp;